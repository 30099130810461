import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { surveyPropTypes, loadingOrErrorPropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';
import { notifyError, notifySuccess } from 'api';
import { surveyDetailThunk, deleteSurveyThunk } from 'store/actions/surveys';
import { makeSurveyDetailSelector } from 'store/reducers/surveys';

import SurveyAdminPageHeader from '../SurveyAdminPageHeader';

class SurveyAdminDeletePage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    survey: PropTypes.oneOfType([surveyPropTypes, loadingOrErrorPropTypes]),
    surveyDetailThunk: PropTypes.func.isRequired,
    deleteSurveyThunk: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.surveyDetailThunk(this.props.match.params.surveyId);
  }

  handleDeleteSurveyClick = async () => {
    const ok = await this.props.deleteSurveyThunk(this.props.survey.id);
    if (ok) {
      notifySuccess('Survey deleted');
      this.props.history.push(window.URLS['surveys:survey_admin_list']());
    } else {
      notifyError('Survey failed to delete');
    }
  };

  render() {
    const { survey } = this.props;

    if (!survey || survey.isLoading) {
      return <Loading />;
    }

    if (survey.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve event data.
        </Alert>
      );
    }
    const reduceAnswers = (accum, question) => accum.concat(question.answers);
    const reduceQuestions = (accum, questionBlock) =>
      accum.concat(questionBlock.questions.reduce(reduceAnswers, []));
    const answers = survey.question_blocks.reduce(reduceQuestions, []);
    return (
      <div className="white-soft-box2">
        <SurveyAdminPageHeader survey={survey}>Delete survey</SurveyAdminPageHeader>
        <h1 className="center mt1">Are you sure?</h1>
        <p className="text-size-lg">
          Deleting this survey ({survey.name}) <strong>will delete all</strong> associated
          questions, question blocks, and responses.
        </p>
        <p className="text-size">Blocks that will be deleted:</p>
        <ul>
          {survey.question_blocks.map((block) => (
            <li key={block.id}>
              {block.title} with {block.questions.length} questions
            </li>
          ))}
          {!survey.question_blocks.length && (
            <li>No question blocks associated with this survey</li>
          )}
        </ul>
        <p className="text-size bold">{answers.length} answers that will be deleted.</p>
        <button type="button" onClick={this.handleDeleteSurveyClick} className="btn btn-danger">
          <i className="fa fa-trash icon-space-r" />
          Delete Survey
        </button>
      </div>
    );
  }
}

const makeMapStateToProps = (state, props) => {
  const surveyDetailSelector = makeSurveyDetailSelector();
  const mapStateToProps = (state, props) => ({
    survey: surveyDetailSelector(state, props.match.params.surveyId),
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  { surveyDetailThunk, deleteSurveyThunk }
)(SurveyAdminDeletePage);
